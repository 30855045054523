<template>
    <div class="stopwatchdiv">    
        <p class="timelabel">{{currentTime }} </p>
        <button class=" stopwatchbutton start" @click="start()" :disabled="timing">Start</button>
        <button class="stopwatchbutton stop" @click="stop()" :disabled="!timing">Stop</button>
        <button class="stopwatchbutton reset" @click="reset()" :disabled="timing">Reset</button>
    </div>
</template>

<script>

export default {
    
    name:'stopwatch',
   
    props:{
        
        
        
    },
    
    data() {
        return {
            timer:null,
            timing:false,
            timerseconds:0,

            minutes:0,
            seconds:0,
        
            currentTime:'00:00'
        };
    },
    methods:{
        isTiming(){
            return this.timing
        },
      updateTime(){
        if(this.timing){
          //  console.log('tick')
          this.timerseconds = this.timerseconds + 1
          this.minutes = Math.floor(this.timerseconds / 60)
          this.seconds = this.timerseconds % 60
          if(this.minutes<10){this.currentTime = '0' + this.minutes}else{this.currentTime=this.minutes}
          this.currentTime=this.currentTime+ ":"
          if(this.seconds<10){this.currentTime = this.currentTime +  '0' + this.seconds}else{this.currentTime=this.currentTime + this.seconds}
        }
        
        },
        start(){
            this.timing=true
           
        },  
        stop(){
            this.timing=false
            this.$emit('stop', this.currentTime)
        },
        reset(){
            this.timerseconds=0
            this.seconds=0
            this.minutes=0
            this.currentTime="00:00"
        }
    },
    computed:{
       

    },
    mounted(){
        this.timer = setInterval(() => {
                this.updateTime()
            }, 1000)
    }
}
</script>

<style scoped>
.stopwatchdiv{
    display:flex;
    flex-direction: row;
    margin:0px;
}

p{

}
.stopwatchbutton{
    background-color:var(--button-color);
    color:var(--button-text-color);
    border: none;
    border-radius:5px;
    width:80px;
    height:25px;
    padding:0px;
    font-size: medium;
    text-decoration: none;
}

.start{

}
.stop{
    margin-left:2px;
}
.reset{
    margin-left:10px;
    margin-right:10px;
}
.timelabel{
    margin:5px 5px 0px 5px;
}
.stopwatchdiv{
    margin:5px;
    font-size:inherit;
}
.stopwatchbutton:disabled{
  background-color:gray;
  color:var(--light-text-color);
}
</style>