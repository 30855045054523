<template>
  <div class="outerDiv" :style="configStyle">
    <navheader v-if="showHeader"/>

    <div class="mainDivAdmin">
      <loginuser  ref='loginuser' v-if="!loggedIn" @afterlogin="afterlogin"/>
      <div v-if="loggedIn">
        <home ref="homepage" v-if="currentNav=='home'" @gotograde="gograde" @gotograde_batch="gotograde_batch" @gotoreports="gotoreports" @gotostatus="gotostatus"/>
        <gradesheet ref="mygradesheet" v-if="currentNav=='gradesheet'" @gotohome="gohome"/>
        <gradesheetbatch ref="mygradesheet_batch" v-if="currentNav=='gradesheet_batch'" @gotohome="gohome" :key="batchKey" />
        <reports ref="myreports" v-if="currentNav=='reports'" @gotohome="gohome"/>
        <status ref="mystatus" v-if="currentNav=='status'" @gotohome="gohome"/>
      </div>

    </div>
    <v-offline @detected-condition="handleConnectivityChange"></v-offline>
  </div>
</template>

<script>
import loginuser from './login/loginuser.vue'
import datamethods from '../services/datamethods'
import {mapFields} from 'vuex-map-fields'
import style from '../style.js'
import navheader from './navheader.vue'
import home from './home/home.vue'
import gradesheet from './grade/gradesheet.vue'
import gradesheetbatch from './grade/gradesheetbatch.vue'
import reports from './reports/reports.vue'
import status from './grade/status.vue'
export default {
  name: 'Start',
  components:{
    loginuser,
   navheader,
   home,
   gradesheet,
   gradesheetbatch,
   reports,
   status
  },
  mixins:[datamethods,style],
   data(){
        return{
          
          currentTime:'',
          trueParam:true,
          batchKey:0
  
        }
   },
   computed:{
        ...mapFields([
            'loggedIn',
            'username',
            'oldpassword',
            'loggedInUser',
            'loggedInUser.isAdmin',
            'logoOverride',
            'token',
            'showHeader',
            'currentNav',
            'currentGradeSheet',
            'isManualTime',
            'online',
            'commentList',
            'adAccount',
            'wasUpdated'
        ]),
      
    
    },
   methods:{
    rerenderBatch(){
      this.batchKey +=1;
    },
    handleConnectivityChange(status){
      let prevStatus = this.online  
      this.online = status
      //if we just went online, then tell the home
      if(this.online && prevStatus==false){
        this.$nextTick(()=>{
          this.$refs.homepage.saveDirtyInstances()
        });
      }
    },
    gograde(){
      
      this.currentNav='gradesheet'
      this.$nextTick(()=>{
        this.$refs.mygradesheet.startGrade();
      }); 
    },
    gotograde_batch(){
      this.currentNav='gradesheet_batch'
      this.$nextTick(()=>{
        this.$refs.mygradesheet_batch.startGrade();
      }); 
    },
    gotostatus(){
      this.currentNav='status'
      this.$nextTick(()=>{
        this.$refs.mystatus.start();
      }); 
    },
    gohome(){
      this.currentNav='home'
      this.$nextTick(()=>{
        this.$refs.homepage.filtergrades()
      });
    },
    gotoreports(){
      this.currentNav='reports'
    },
      logout(){
          this.loggedIn=false;
      },
      receiveMessage(event){
        try{
                var msg =  JSON.parse(event.data)
            }
            catch(err){
                return
            }
            
            var action = msg.action
            if(action !=undefined){
            
               // console.log("PA Receive Msg: " + action)
                switch (action) {
                    case 'refresh':
                      this.refreshCurrentView()
                        break;
                    case 'appupdate':
                        location.reload(true);
                    default:
                        break;
                }
                
            }
       
      },
      async checkLastUpdate(){
        var needsClear =false

        //get the last update value from config
        var lastUpdate = '' 
        //if(window.LAST_UPDATE_DATE!=undefined){lastUpdate=window.LAST_UPDATE_DATE}

        //from api
        try {
          var response = await this.callAPI(null,'lastupdatedate')
            //handle response
            if(response.Successful){
              lastUpdate = response.StringResponse
 
            }

        } catch (error) {
          
        }


        
        //if we don't have a setting, then don't do anything
        if(lastUpdate==''){return}


        //get last update value from localstorage
        var storedLastUpdate = ''
        storedLastUpdate=localStorage.getItem('lastUpdate')

        console.log('lastupdate ' + lastUpdate + ' ' + storedLastUpdate)

        if(storedLastUpdate==undefined){needsClear=true}
        else if(storedLastUpdate!=lastUpdate){needsClear=true}

        //if we need to clear...
        if(needsClear){
          console.log('clearing local storage...')
          localStorage.removeItem('instances')
          localStorage.removeItem('timeData')
          localStorage.removeItem('sequences')
          //set flag in store
          this.wasUpdated=true
        }
        
        //store the last update
        localStorage.setItem('lastUpdate',lastUpdate)
        //console.log('writing last update to local storage')
      },
      afterlogin(){
        this.checkLastUpdate()
      }
   },
  
   mounted(){
    //***temp */
    /* this.loggedInUser.isAdmin=false 
    this.loggedIn=true
    this.currentNav='home' */
    //***temp */
    this.currentNav='home'
    
    this.setColors()
    window.addEventListener('message', this.receiveMessage)

    let urlParams = new URLSearchParams(window.location.search)
    let tmp = urlParams.get('email')
    if(tmp!=null){
        this.username = tmp;
    }

    let tmp2 = urlParams.get('temp')
    if(tmp2!=null){
        this.oldpassword = tmp2;
        this.$nextTick(()=>{
        //this.changepass()
        this.$refs.loginuser.changepassword();
        });
    }
    tmp = urlParams.get('header')
    if(tmp!=null){this.showHeader=tmp=='true'; }

    tmp = urlParams.get('token')
    //console.log('token ' + tmp)
    if(tmp!=null){
       // console.log('sso!')
        this.$refs.loginuser.post_login(tmp)
      }

      this.isManualTime=window.MANUAL_TIME

      this.checkLastUpdate()
   }
}
</script>

<style lang="scss">
@import "@/assets/stylebase.scss";
@media print{
    .noprint .noprint *{
        display:none !important;
    }
}
body{
  height:100vh; 
  height: -webkit-fill-available;
  width:98.7vw;
  padding:0px;
  margin:0px;
  font-size:medium;
  color:var(--text-color);
}
.outerDiv{
  margin:0px;
  padding:0px;
    height:95vh;
    display:flex;
    flex-direction:column;
    //justify-content:space-between;
    //display:grid;
    //grid-template-rows:90px auto;

}

.mainDivAdmin{
  
     grid-row:2;

    background-color:var(--background-color);
    padding:20px;

     
}
.widebutton{

border: none;
border-radius:5px;
width:150px;
height:50px;
padding:0px;
font-size: medium;
margin:10px;
text-decoration: none;

}
.extrawidebutton{

border: none;
border-radius:5px;
width:260px;
height:50px;
padding:0px;
font-size: medium;
margin:10px;
text-decoration: none;

}
.narrowbutton{

border: none;
border-radius:5px;
min-width:50px;
height:30px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.actionbutton{

border: none;
border-radius:5px;
width:100px;
height:40px;
padding:0px;
font-size: medium;
margin:10px;
text-decoration: none;

}
.update{
    font-weight: bold;
    color:blue;
}
/* input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display:none;
} */
</style>
